/*
 |-----------------------------------------------------------------------------
 | components/atoms/Registration/Registration.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as IRegistration from './types';

const Registration: FC<IRegistration.IProps> = ({
	coNo,
	darkMode,
	vatNo,
	utilities,
}) => {
	// ray('Debug atom Registration:', {
	// 	coNo: coNo,
	// 	darkMode: darkMode,
	// 	utilities: utilities,
	// 	vatNo: vatNo,
	// }).red();

	return (
		<p
			className={`
				font-medium
				text-base
				sm:text-sm
				${darkMode ? 'text-brand-6' : 'text-brand-6'}
				${utilities ? utilities : null}
			`}
			data-testid="registration"
		>
			{coNo && <span>Company Registration Number: {coNo}</span>} is a
			limited company registered in England and Wales.{' '}
			{vatNo && (
				<span itemProp="vatID">VAT Registration Number: {vatNo}</span>
			)}
		</p>
	);
};

export default Registration;
