/*
 |-----------------------------------------------------------------------------
 | components/molecules/ContactPoint/ContactPoint.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import dynamic from 'next/dynamic';
import { get } from 'lodash';
// import { ray } from 'node-ray/web';

import * as IContactPoint from './types';

const Email = dynamic(() => import('@/helpers/Email'));
const Phone = dynamic(() => import('@/helpers/Phone'));
const Website = dynamic(() => import('@/helpers/Website'));

const ContactPoint: FC<IContactPoint.IProps> = ({
	email,
	hideLabels,
	phone,
	utilities,
	variant,
	website,
}) => {
	// ray('Debug molecule ContactPoint:', {
	// 	email: email,
	// 	hideLabels: hideLabels,
	// 	phone: phone,
	// 	utilities: utilities,
	// 	variant: variant,
	// 	website: website,
	// }).blue();

	let classNames;
	switch (variant) {
		case 'footer':
			classNames = {
				li: `
					relative
					after:absolute
					after:bg-brand-4
					after:h-0.5
					after:invisible
					after:left-0
					after:right-0
					after:scale-x-0
					after:top-full
					after:transition-all
					hover:after:scale-x-100
					hover:after:visible
				`,
				ul: `
					font-medium
					space-y-4
					text-lg
					text-brand-4
					sm:text-base
					${utilities ? utilities : ''}
				`,
			};
			break;
		default:
			classNames = {
				li: `
					relative
					after:absolute
					after:bg-brand-1
					after:h-0.5
					after:invisible
					after:left-0
					after:right-0
					after:scale-x-0
					after:top-full
					after:transition-all
					hover:after:scale-x-100
					hover:after:visible
				`,
				ul: `
					font-medium
					text-2xl
					text-brand-1
					sm:text-xl
					${utilities ? utilities : ''}
				`,
			};
	}

	const Method = ({ children, text }) => {
		return (
			<li>
				{hideLabels ? (
					<>
						<span className={`sr-only`}>{text}:</span>{' '}
					</>
				) : (
					`${text}: `
				)}
				{children}
			</li>
		);
	};

	return (
		<ul className={get(classNames, 'ul')}>
			{phone && (
				<Method text="Phone">
					<Phone number={phone} utilities={get(classNames, 'li')} />
				</Method>
			)}
			{email && (
				<Method text="Email">
					<Email address={email} utilities={get(classNames, 'li')} />
				</Method>
			)}
			{website && (
				<Method text="Website">
					<Website
						name={get(website, 'name')}
						url={get(website, 'url')}
						utilities={get(classNames, 'li')}
					/>
				</Method>
			)}
		</ul>
	);
};

export default ContactPoint;
