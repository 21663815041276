/*
 |-----------------------------------------------------------------------------
 | components/organisms/Footer/index.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

export { default } from './Footer';
