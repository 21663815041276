/*
 |-----------------------------------------------------------------------------
 | components/molecules/SocialMedia/SocialMedia.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, lowerFirst, map } from 'lodash';
// import { ray } from 'node-ray/web';

import Icon from '@/atoms/Icon';

import * as ISocialMedia from './types';

const SocialMedia: React.FC<ISocialMedia.IProps> = ({
	darkMode,
	hideIcons,
	platforms,
	utilities,
	variant,
}) => {
	// ray('Debug molecule SocialMedia:', {
	// 	darkMode: darkMode,
	// 	hideIcons: hideIcons,
	// 	platforms: platforms,
	// 	utilities: utilities,
	// 	variant: variant,
	// }).blue();

	let classNames;
	switch (variant) {
		case 'footer':
			classNames = {
				a: `
					text-brand-4
					text-lg
					sm:text-base
				`,
				i: `
				`,
				li: `
				`,
				ul: `
					font-medium
					space-y-4
				`,
			};
			break;
		default:
			classNames = {
				a: `
					text-brand-1
					text-2xl
					sm:text-xl
				`,
				i: `
					${
						darkMode
							? 'fill-brand-1 group-hover:fill-brand-1'
							: 'fill-brand-1 group-hover:fill-brand-1'
					}
				`,
				li: `
					inline-block
					mr-2
				`,
				ul: `
					font-bold
					text-[0]
				`,
			};
	}

	return (
		<ul
			className={`${get(classNames, 'ul')} ${utilities ? utilities : ''}`}
		>
			{map(platforms, (platform, index) => (
				<li
					key={`platform-${index}__${get(platform, 'title')}`}
					className={get(classNames, 'li')}
				>
					<a
						className={`
							group
							relative
							after:absolute
							after:bg-brand-4
							after:h-0.5
							after:invisible
							after:left-0
							after:right-0
							after:scale-x-0
							after:top-full
							after:transition-all
							hover:after:scale-x-100
							hover:after:visible
							${get(classNames, 'a')}
						`}
						href={get(platform, 'url')}
						rel="external noopener noreferrer"
						target="_blank"
						title={`Visit ${get(platform, 'title')}`}
					>
						{hideIcons ? (
							get(platform, 'title')
						) : (
							<Icon
								id={lowerFirst(get(platform, 'title'))}
								utilities={`
									h-6
									transition-colors
									w-6
									${get(classNames, 'i')}
								`}
							/>
						)}

						{/*<Icon*/}
						{/*	id="r-link"*/}
						{/*	utilities={`*/}
						{/*		h-2*/}
						{/*		ml-2*/}
						{/*		opacity-25*/}
						{/*		transition-opacity*/}
						{/*		w-2*/}
						{/*		group-hover:opacity-100*/}
						{/*		${darkMode ? 'fill-current' : 'fill-current'}*/}
						{/*	`}*/}
						{/*/>*/}
					</a>
				</li>
			))}
		</ul>
	);
};

export default SocialMedia;
