/*
 |-----------------------------------------------------------------------------
 | components/atoms/Logo/Logo.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import { useRouter } from 'next/router';

import * as ILogo from './types';

const Logo: FC<ILogo.IProps> = ({ id, darkMode, name, utilities }) => {
	// ray('Debug atom Logo:', {
	// 	darkMode: darkMode,
	// 	name: name,
	// 	utilities: utilities,
	// }).red();

	const router = useRouter();

	return (
		<svg
			className={`
				${
					darkMode
						? 'fill-brand-6'
						: 'fill-white'
				}
				${utilities ? utilities : ''}
			`}
			aria-label={name}
			data-testid="logo"
			role="img"
		>
			<use xlinkHref={`/imgs/lgo_sprite.svg#${id ? id : 'brand'}`}></use>
		</svg>
	);
};

export default Logo;
