/*
 |-----------------------------------------------------------------------------
 | components/atoms/Copyright/index.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

export { default } from './Copyright';
