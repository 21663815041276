/*
 |-----------------------------------------------------------------------------
 | components/molecules/Address/Address.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as IAddress from './types';

const Address: FC<IAddress.IProps> = ({
	country,
	locality,
	postcode,
	region,
	street,
	utilities,
	variant,
}) => {
	// ray('Debug molecule Address:', {
	// 	country: country,
	// 	locality: locality,
	// 	postcode: postcode,
	// 	region: region,
	// 	street: street,
	// 	utilities: utilities,
	// 	variant: variant,
	// }).blue();

	const AddressLine = ({ schema, text }) => {
		return (
			<span
				className={
					variant
						? `
							after:content-[',']
							last:after:content-['']
						`
						: `block`
				}
				itemProp={schema}
			>
				{text}
			</span>
		);
	};

	let classNames;
	switch (variant) {
		case 'footer':
			classNames = `
				font-medium
				space-x-1
				text-base
				text-brand-6
				sm:text-sm
			`;
			break;
		default:
			classNames = `
				font-normal
				text-brand-1
				text-xl
				sm:text-lg
			`;
	}

	return (
		<address
			className={`
			not-italic
			${classNames}
			${utilities ? utilities : ''}
		`}
		>
			{variant && <span>Our registered office is:</span>}
			{street && <AddressLine schema="streetAddress" text={street} />}
			{locality && (
				<AddressLine schema="addressLocality" text={locality} />
			)}
			{region && <AddressLine schema="addressRegion" text={region} />}
			{postcode && <AddressLine schema="postalCode" text={postcode} />}
			{country && <AddressLine schema="postalCode" text={country} />}
		</address>
	);
};

export default Address;
