/*
 |-----------------------------------------------------------------------------
 | components/organisms/Footer/Footer.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { get, map } from 'lodash';
// import { ray } from 'node-ray/web';

import Address from '@/molecules/Address';
import ContactPoint from '@/molecules/ContactPoint';
import Container from '@/layouts/Container';
import Copyright from '@/atoms/Copyright';
import FooterMenu from '@/molecules/FooterMenu';
import Logo from '@/atoms/Logo';
import Registration from '@/atoms/Registration';
import SocialMedia from '@/molecules/SocialMedia';
import { country } from '@/utils/helpers';
import { getMenu } from '@/utils/menus';
import { menusSelector } from '@/features/menus';
import { organisationSelector } from '@/features/organisation';
import { useAppSelector } from '@/app/hooks';

const Footer = ({ darkMode }) => {
	// ray('Debug organism Footer:', {
	// 	darkMode: darkMode,
	// }).purple();

	const menus = useAppSelector(menusSelector);
	const organisation = useAppSelector(organisationSelector);

	const legalPages = getMenu('legal-pages', menus);
	const menu = getMenu('footer', menus);

	return (
		<footer
			className={`
				py-16
				${darkMode ? 'bg-brand-2' : 'bg-brand-2'}
			`}
			itemScope
			itemType="http://schema.org/Footer"
		>
			<Container
				utilities={`
					grid
					grid-cols-4
					md:grid-cols-3
					custom:grid-cols-2
				`}
			>
				{menu && (
					<FooterMenu
						darkMode={darkMode}
						items={get(menu, 'items')}
						label={get(menu, 'label')}
						variant="style1"
					/>
				)}

				{get(organisation, 'contactPoints') &&
					map(
						get(organisation, 'contactPoints'),
						(contactPoint, index) => (
							<ContactPoint
								key={index}
								email={get(contactPoint, 'email')}
								hideLabels
								phone={get(contactPoint, 'phone')}
								utilities={`col-span-2 md:col-span-1`}
								variant="footer"
							/>
						)
					)}

				{get(organisation, 'platforms') && (
					<SocialMedia
						hideIcons
						platforms={get(organisation, 'platforms')}
						utilities={`custom:mt-8`}
						variant="footer"
					/>
				)}
			</Container>
			<Container
				utilities={`
					grid
					grid-cols-4
					items-end
					mt-16
					md:grid-cols-2
					custom:grid-cols-1
					custom:space-y-8
				`}
			>
				<Logo
					darkMode
					id="brand-solid"
					name={get(organisation, 'name')}
					utilities={`
						col-span-3
						h-[168px]
						opacity-20
						w-[419px]
						md:col-span-1
						md:mt-8
						md:h-[120px]
						md:w-[300px]
						xs:h-[100px]
						xs:w-[250px]
					`}
				/>

				{/*<svg*/}
				{/*	className={`h-[45px] w-[231px] md:ml-auto custom:ml-0`}*/}
				{/*	aria-label="The Royal College of Ophthalmologists"*/}
				{/*	role="img"*/}
				{/*>*/}
				{/*	<use xlinkHref="/imgs/lgo_sprite.svg#the-royal-college-of-ophthalmologists"></use>*/}
				{/*</svg>*/}
			</Container>
			<Container
				utilities={`
					grid
					grid-cols-4
					items-end
					mt-8
					md:grid-cols-1
				`}
			>
				<div className={`col-span-3 sm:grid-cols-1`}>
					{get(organisation, 'name') && (
						<Copyright
							darkMode={darkMode}
							name={get(organisation, 'name')}
							year={2022}
							utilities={`inline mr-1`}
						/>
					)}

					{get(organisation, 'registrations') && (
						<Registration
							coNo={get(organisation, ['registrations', 'coNo'])}
							darkMode={darkMode}
							vatNo={get(organisation, [
								'registrations',
								'vatNo',
							])}
						/>
					)}

					{get(organisation, 'addresses') &&
						map(
							get(organisation, 'addresses'),
							(address, index) => (
								<Address
									key={index}
									country={country(get(address, 'country'))}
									locality={get(address, 'locality')}
									postcode={get(address, 'postcode')}
									region={get(address, 'region')}
									street={get(address, 'street')}
									variant="footer"
								/>
							)
						)}
				</div>

				{legalPages && (
					<FooterMenu
						darkMode={darkMode}
						items={get(legalPages, 'items')}
						label={get(legalPages, 'label')}
						utilities={`md:mt-8`}
						variant="style2"
					/>
				)}
			</Container>
		</footer>
	);
};

export default Footer;
