/*
 |-----------------------------------------------------------------------------
 | components/atoms/Copyright/Copyright.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import { now } from '@/utils/helpers';

import * as ICopyright from './types';

const Copyright: FC<ICopyright.IProps> = ({
	darkMode,
	name,
	utilities,
	year,
}) => {
	// ray('Debug atom Copyright:', {
	// 	darkMode: darkMode,
	// 	name: name,
	// 	utilities: utilities,
	// 	year: year,
	// }).red();

	return (
		<p
			className={`
				font-medium
				text-base
				sm:text-sm
				${darkMode ? 'text-brand-6' : 'text-brand-6'}
				${utilities ? utilities : ''}
			`}
			data-testid="copyright"
		>
			&copy; {now(year)} {name}.
		</p>
	);
};

export default Copyright;
