/*
 |-----------------------------------------------------------------------------
 | components/molecules/FooterMenu/FooterMenu.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, map } from 'lodash';
import Link from 'next/link';
import dynamic from 'next/dynamic';
// import { ray } from 'node-ray/web';

import * as IFooterMenu from './types';

const Icon = dynamic(() => import('@/atoms/Icon'));

const FooterMenu: React.FC<IFooterMenu.IProps> = ({
	darkMode,
	items,
	label,
	utilities,
	variant,
}) => {
	// ray('Debug molecule FooterMenu:', {
	// 	darkMode: darkMode,
	// 	items: items,
	// 	label: label,
	// 	utilities: utilities,
	//	variant: variant,
	// }).blue();

	let classNames;
	switch (variant) {
		case 'style1':
			classNames = {
				ul: `
					font-medium
					space-y-4
					text-lg
					sm:text-base
				`,
				li: `
					group
					relative
					after:absolute
					after:h-0.5
					after:invisible
					after:left-0
					after:right-0
					after:scale-x-0
					after:top-full
					after:transition-all
					hover:after:scale-x-100
					hover:after:visible
					${darkMode ? 'text-brand-4 after:bg-brand-4' : 'text-brand-4 after:bg-brand-4'}
				`,
			};
			break;
		case 'style2':
			classNames = {
				ul: `
					flex
					font-medium
					space-x-4
					text-base
					sm:text-sm
				`,
				li: `
					group
					relative
					after:absolute
					after:h-0.5
					after:invisible
					after:left-0
					after:right-0
					after:scale-x-0
					after:top-full
					after:transition-all
					hover:after:scale-x-100
					hover:after:visible
					${darkMode ? 'text-brand-6 after:bg-brand-6' : 'text-brand-6 after:bg-brand-6'}
				`,
			};
			break;
	}

	const externalLink = (label, url, utilities) => {
		return (
			<a
				className={utilities}
				href={url}
				rel="external noopener noreferrer"
				target="_blank"
			>
				{label}

				<Icon
					id="r-link"
					utilities={`
						h-3
						inline-block
						ml-2
						opacity-25
						transition-opacity
						w-3
						group-hover:opacity-100
					`}
				/>
			</a>
		);
	};

	const internalLink = (label, path, utilities) => {
		return (
			<Link href={path || '#'} legacyBehavior passHref>
				<a className={utilities} href="jsx-a11y">
					{label}
				</a>
			</Link>
		);
	};

	return (
		<ul
			className={`
				${get(classNames, 'ul')}
				${utilities ? utilities : ''}
			`}
			aria-label={label}
			data-testid="footerMenu"
		>
			{map(items, (item, index) => (
				<li
					key={`footer-menu-item-${index}`}
					className={`
					`}
				>
					{get(item, 'url')
						? externalLink(
								get(item, 'label'),
								get(item, 'url'),
								get(classNames, 'li')
							)
						: internalLink(
								get(item, 'label'),
								get(item, 'path'),
								get(classNames, 'li')
							)}
				</li>
			))}
		</ul>
	);
};

export default FooterMenu;
