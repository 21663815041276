/*
 |-----------------------------------------------------------------------------
 | components/atoms/Icon/Icon.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as IIcon from './types';

const Icon: FC<IIcon.IProps> = ({ id, utilities }) => {
	// ray('Debug atom Icon:', {
	// 	icon: icon,
	// 	utilities: utilities,
	// }).red();

	return (
		<svg
			className={utilities ? utilities : null}
			aria-hidden="true"
			data-testid="icon"
		>
			<use xlinkHref={`/imgs/icn_sprite.svg#${id}`}></use>
		</svg>
	);
};

export default Icon;
